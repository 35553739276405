import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { createContext, Dispatch, SetStateAction } from 'react';
import { z } from 'zod';

import { axiosInstance } from '~/api/api';
import { authenticatedSsoUser, guestSsoUser } from '~/api/endpoints/sso';
import AppRoutes from '~/pages/routes';
import { setSentryUser } from '~/sentryCreateBrowserRouter';

import { LocalStorageKey } from '../useLocalStorage';

const omitFromUser = { accessToken: true, webshopKey: true } as const;

const userCheckInDetailsSchema = z.object({
  checkedIn: z.boolean().nullable(),
  // checkOutTimestamp: z
  //   .string()
  //   .transform((v) => new Date(v.replace(' ', 'T')))
  //   .nullable(), // Stored as a string in localStorage
});

const guestCheckInDetailsSchema = z.object({
  checkedIn: z.literal(false),
  // checkOutTimestamp: z.literal(null),
});

export const userSchema = z.union([
  guestSsoUser.omit(omitFromUser).merge(guestCheckInDetailsSchema),
  authenticatedSsoUser.omit(omitFromUser).merge(userCheckInDetailsSchema),
]);

export const authSchema = z.nullable(
  z.object({
    accessToken: z.string(),
    webshopKey: z.string(),
    user: userSchema,
  }),
);

export type Auth = z.infer<typeof authSchema>;

export interface AuthContextValue {
  auth: Auth | null;
  setAuth: Dispatch<SetStateAction<Auth>>;
}

/** Globally available (also outside of React) authentication object. */
export const globalAuth: { value: Auth | null } = { value: null };

/** Signs out the user locally (on the device). Nothing is sent to the server. */
export const signOut = () => {
  globalAuth.value = null;
  for (const key of Object.values(LocalStorageKey)) {
    window.localStorage.removeItem(key);
  }
  window.location.href = AppRoutes.Unauthorized;
};

// Initialize the global auth value from local storage before React renders.
const authStringFromLocalStorage = window.localStorage.getItem(LocalStorageKey.Auth);
if (authStringFromLocalStorage) {
  let authFromStorage;
  try {
    authFromStorage = JSON.parse(authStringFromLocalStorage);
    globalAuth.value = authSchema.parse(authFromStorage);
    setSentryUser(globalAuth.value?.user);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    Sentry.captureException('Failed to parse auth from storage', {
      originalException: e,
      user: authFromStorage.user,
    });
    window.localStorage.removeItem(LocalStorageKey.Auth);
  }
}

// Set/unset the authorization headers on every request for the app-wide Axios instance.
const authorizationHeader = 'Authorization';
const webshopKeyHeader = 'x-webshop-key';
axiosInstance.interceptors.request.use((config) => {
  if (globalAuth.value?.accessToken) {
    config.headers[authorizationHeader] = `Bearer ${globalAuth.value.accessToken}`;
  } else {
    delete config.headers[authorizationHeader];
  }
  if (globalAuth.value?.webshopKey) {
    config.headers[webshopKeyHeader] = globalAuth.value.webshopKey;
  } else {
    delete config.headers[webshopKeyHeader];
  }

  // Explicitly set the accept header to JSON to indicate our intentions.
  config.headers['Accept'] = 'application/json';

  return config;
});

// Handle 401 and 405 errors by clearing the auth and redirecting to the unauthorized page.
const HTTP_UNAUTHORIZED = 401;
const HTTP_METHOD_DENIED = 405;
axiosInstance.interceptors.response.use(
  (response) => {
    // Explicitly set the content type to JSON, otherwise Zodios won't validate the response at all 🤷
    if (response.headers['Content-Type']) {
      response.headers['Content-Type'] = 'application/json';
    } else {
      response.headers['content-type'] = 'application/json';
    }
    return response;
  },
  (error: AxiosError) => {
    if (
      error.response?.status === HTTP_UNAUTHORIZED ||
      (error.response?.status === HTTP_METHOD_DENIED && !error.request.responseURL.includes('styling')) // ! temp fix
    ) {
      signOut();
    }
    return Promise.reject(error);
  },
);

export const AuthContext = createContext<AuthContextValue>({
  auth: null,
  setAuth: () => {
    // eslint-disable-next-line no-console
    console.warn('AuthContext.setAuth called before initialized');
  },
});
