import { CustomContentProps, SnackbarContent, useSnackbar } from 'notistack';
import { forwardRef, useCallback } from 'react';

import Alert from '../../atoms/Alert/Alert';

declare module 'notistack' {
  interface VariantOverrides {
    // disable variant 'default' to prevent type error with Alert type
    default: false;
    // adds `closable` variant and specifies the
    // "extra" props it takes in options of `enqueueSnackbar`
    closable: {
      title: string;
    };
  }
}

interface ReportCompleteProps extends CustomContentProps {
  title: string;
}

// eslint-disable-next-line react/display-name
const Snackbar = forwardRef<HTMLDivElement, ReportCompleteProps>((props, ref) => {
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(props.id);
  }, [props.id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref} role="alert">
      <Alert
        sx={{
          width: '100%',
          boxShadow: 2,
          typography: 'body/small',
        }}
        title={props.title}
        type={props.variant}
        onClick={handleDismiss}
      >
        {props.message}
      </Alert>
    </SnackbarContent>
  );
});

export default Snackbar;
