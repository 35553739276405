import { z } from 'zod';

import { contactSchemaSimple } from './contact.schema';
import { filterGroupItemProps, itemBaseSchema } from './item.schema';
import { addDatesTransformFn, addTypeNameFn, dateSchema, militaryTimeSchema, playerCountSchema } from './utils.schema';

const schematicAvailabilityStatusSchema = z.enum(['unavailable', 'available']);

export type SchematicAvailabilityStatus = z.infer<typeof schematicAvailabilityStatusSchema>;

export const SchematicItemSchema = itemBaseSchema
  .merge(filterGroupItemProps)
  .extend({
    startTimeBlock: z.string().nullable(),
  })
  .transform(addDatesTransformFn)
  .transform(addTypeNameFn('schematicItem'));

export type SchematicItem = z.infer<typeof SchematicItemSchema>;

export const schematicPeriodSchema = z.object({
  color: z.string().nullable(),
  dateFrom: dateSchema,
  dateSchedule: dateSchema,
  dateTo: dateSchema,
  description: z.string().nullable(),
  disabled: z.boolean().nullish(),
  duration: z.coerce.string().nullable(),
  group: z.string().nullable(),
  groupDescription: z.string().nullable(),
  icon: z.string().nullable(),
  items: z.array(SchematicItemSchema),
  itemKey: z.string().nullable(),
  itemType: z.string().nullable(),
  location: z.string(),
  locationCode: z.string().optional(),
  ownItem: z.boolean(),
  playerCount: playerCountSchema,
  players: z.array(contactSchemaSimple).nullable(),
  reservationType: z.union([z.literal('none'), z.literal('reservation'), z.literal('slot')]),
  status: schematicAvailabilityStatusSchema,
  timeFrom: militaryTimeSchema,
  timeTo: militaryTimeSchema,
});

export type SchematicPeriod = z.infer<typeof schematicPeriodSchema>;
