import { Container } from '@mui/material';
import { FC, lazy, Suspense } from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';

import { env } from '~/env';

const DevMenu = lazy(() => import('../../components/DevMenu'));

const MainLayout: FC = () => {
  const isWithinIframe = window.self !== window.top;

  return (
    <>
      <ScrollRestoration />
      {env.VITE_APP_ENV === 'development' && (
        <Suspense fallback={null}>
          <DevMenu />
        </Suspense>
      )}
      <Container
        disableGutters={true}
        maxWidth="md"
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          overflow: 'visible',
          margin: { md: 'auto' },
          ...(isWithinIframe
            ? {
                maxWidth: '100% !important',
              }
            : { height: '100%', maxHeight: { md: 'min(900px,100svh)' } }),
        }}
      >
        <Outlet />
      </Container>
    </>
  );
};

export default MainLayout;
