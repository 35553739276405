import { Alert as MuiAlert, AlertProps, AlertTitle, Box, IconButton } from '@mui/material';
import { BellRinging, CheckCircle, IconWeight, Info, Warning, WarningCircle, X } from '@phosphor-icons/react';
import { VariantType } from 'notistack';
import { FC, PropsWithChildren } from 'react';

export interface ExtendedAlertProps extends AlertProps {
  type?: VariantType;
  title?: string;
  weight?: IconWeight;
  onClick?: () => void;
}

const Alert: FC<PropsWithChildren<ExtendedAlertProps>> = ({
  type,
  onClick,
  title,
  weight = 'fill',
  children,
  ...props
}) => {
  return type === 'closable' ? (
    <MuiAlert
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          sx={{ boxShadow: 'none' }}
          tabIndex={0}
          onClick={onClick}
        >
          <X size={24} />
        </IconButton>
      }
      icon={false}
      severity="info"
      variant="filled"
      {...props}
    >
      <Box sx={{ display: 'flex', gap: 1 }}>
        <BellRinging size={18} weight="fill" />
        <AlertTitle>{title}</AlertTitle>
      </Box>
      {children}
    </MuiAlert>
  ) : (
    <MuiAlert
      iconMapping={{
        info: <Info weight={weight} />,
        error: <WarningCircle weight={weight} />,
        warning: <Warning weight={weight} />,
        success: <CheckCircle weight={weight} />,
      }}
      severity={type}
      {...props}
    >
      {children}
    </MuiAlert>
  );
};

export default Alert;
