import z from 'zod';

export const orderDataSchema = z.object({
  header: z.object({
    hidePlatformFee: z.boolean(),
    platformFee: z.number(),
    salesTaxAmount: z.number(),
    subTotalAmount: z.number(),
    totalAmount: z.number(),
  }),
  lines: z.array(
    z.object({
      amount: z.number(),
      description: z.string(),
      price: z.number(),
      quantity: z.number(),
    }),
  ),
});

export type OrderData = z.infer<typeof orderDataSchema>;
