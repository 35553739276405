import { format } from 'date-fns/format';
import { parse } from 'date-fns/parse';
import { parseISO } from 'date-fns/parseISO';
import { z } from 'zod';

const militaryTimeFormat = 'HH:mm';
const militaryTimeRegex = /^(?:[01]\d|2[0-3]):(?:[0-5]\d)$/;

export const dateFormat = 'yyyy-MM-dd';
export const dateRegex = /^(?:[0-9]{4})-(?:(?:0[1-9])|(?:1[0-2]))-(?:(?:0[1-9])|(?:[1-2][0-9])|(?:3[0-1]))$/;

export const dateSchema = z.coerce.date();
export const militaryTimeSchema = z.string().regex(militaryTimeRegex);

const lastDayOfTheWeek = 7; // 1 = Monday, 7 = Sunday
export const dayOfWeekSchema = z.number().int().min(1).max(lastDayOfTheWeek);

export const dateTimeViewSchema = z.enum(['date_time_duration', 'date_time_to']);

export const playerCountSchema = z.coerce.number().nullable();

export function formatAmount(amount: number | undefined) {
  return amount?.toLocaleString('nl-NL', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
export function formatEuro(amount: number | undefined) {
  return amount?.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' });
}
// Note that language codes are lowercase for `i18next`, but the API provides them as uppercase
export const languageCodeSchema = z.enum(['DE', 'EN', 'FR', 'NL']);
export type LanguageCode = z.infer<typeof languageCodeSchema>;

/** Helper function to lowercase a string preserving the literal types of the input. */
export const toLowerCaseTyped = <S extends string>(strIn: S): Lowercase<S> => strIn.toLowerCase() as Lowercase<S>;

/**
 * Zod transform function to add `dateTimeFrom` and `dateTimeTo` fields to
 * an object based on existing `dateFrom`, `timeFrom`, `dateTo` and `timeTo` properties
 */
export const addDatesTransformFn = <
  T extends { dateFrom: Date | null; timeFrom: string | null; dateTo: Date | null; timeTo: string | null },
>(
  item: T,
): T & { dateTimeFrom: Date; dateTimeTo: Date } => {
  const dateFrom = (typeof item.dateFrom === 'string' ? parseISO(item.dateFrom) : item.dateFrom) ?? new Date(0);
  const dateTo = (typeof item.dateTo === 'string' ? parseISO(item.dateTo) : item.dateTo) ?? new Date(0);

  return {
    ...item,
    dateFrom,
    dateTo,
    dateTimeFrom: parse(
      `${format(dateFrom, dateFormat)} ${item.timeFrom ?? '00:00'}`,
      `${dateFormat} ${militaryTimeFormat}`,
      new Date(),
    ),
    dateTimeTo: parse(
      `${format(dateTo, dateFormat)} ${item.timeTo ?? '00:00'}`,
      `${dateFormat} ${militaryTimeFormat}`,
      new Date(),
    ),
  };
};

export const addTypeNameFn =
  <T extends string, Z extends object>(__typename: T) =>
  (schema: Z): Z & { __typename: T } => ({ ...schema, __typename });
