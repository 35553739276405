import { z } from 'zod';

export const actionBaseSchema = z.object({
  name: z.string(),
  disabled: z.boolean().nullable(),
});

// Item
export const itemActionType = z.enum(['add_to_cart']);
export const itemActionSchema = actionBaseSchema.merge(
  z.object({
    type: itemActionType,
  }),
);
export type ItemActionType = z.infer<typeof itemActionType>;
export type ItemAction = z.infer<typeof itemActionSchema>;

// Open item
export const openItemActionType = z.enum([
  'confirm_item',
  'edit_item',
  'view_item',
  'add_extratime_item',
  'update_item',
  'cancel_item',
  'stop_item',
  'back_to_filters',
]);
export const openItemActionSchema = actionBaseSchema.merge(
  z.object({
    type: openItemActionType,
  }),
);
export type OpenItemActionType = z.infer<typeof openItemActionType>;
export type OpenItemAction = z.infer<typeof openItemActionSchema>;

// Cart
export const cartActionType = z.enum([
  'confirm_cart',
  'confirm_cartline',
  'delete_cart',
  'edit_cart',
  'empty_cart',
  'start_webcollect',
  'reconcile_and_confirm',
]);
export const cartActionSchema = actionBaseSchema.merge(
  z.object({
    type: cartActionType,
  }),
);
export type CartActionType = z.infer<typeof cartActionType>;
export type CartAction = z.infer<typeof cartActionSchema>;

// Cart line
export const cartLineActionType = z.enum([
  'confirm_cart',
  'confirm_cartline',
  'delete_cartline',
  'edit_cartline',
  'update_cartline',
]);
export const cartLineActionSchema = actionBaseSchema.merge(
  z.object({
    type: cartLineActionType,
  }),
);
export type CartLineActionType = z.infer<typeof cartLineActionType>;
export type CartLineAction = z.infer<typeof cartLineActionSchema>;

// Player
export const playerActionType = z.enum(['add_to_reservation', 'delete_from_reservation']);
export const playerActionSchema = actionBaseSchema.merge(
  z.object({
    type: playerActionType,
  }),
);
export type PlayerActionType = z.infer<typeof playerActionType>;
export type PlayerAction = z.infer<typeof playerActionSchema>;

// General
export type ActionType = ItemActionType | OpenItemActionType | CartLineActionType | CartActionType | PlayerActionType;
export type Action = ItemAction | OpenItemAction | CartLineAction | CartAction | PlayerAction;
