const AppRoutes = {
  /** Homepage showing the user's pending cart and/or open items (i.e. reservations). */
  Dashboard: '/',
  /** Details of the user's cart with the option to add promo codes and complete the reservation. */
  CartOverview: '/cart/overview',
  /** A single unfinished reservation that is in the User's cart, by an item's `type` and `key`. */
  Checkout: '/cart/:type/:key',
  /** Schematic view of all items at the user's section (i.e. club) */
  ClubSchematic: '/club/:filterGroupId/schematic',
  /** List view of all available items at the user's section by `filterGroupId` (i.e. club) */
  ClubList: '/club/:filterGroupId/list',
  // Page for editing an open item (i.e. reservation).
  ReservationDetails: '/items/:type/:key',
  /** Entry point for the application. The native app will open this route with an SSO-token. */
  SsoSignIn: '/sso/:token',
  /** Error page when the user is not authorized or the authorization has expired. */
  Unauthorized: '/unauthorized',
} as const satisfies Record<string, `/${string}`>;

export type AppRoutes = keyof typeof AppRoutes;
export type AppRoute = (typeof AppRoutes)[keyof typeof AppRoutes];

type RoutesEnum = typeof AppRoutes;
type Params = `${string}:${string}`;
type ExtractParam<Route, NextPart> = Route extends `:${infer Param}` ? Record<Param, string> & NextPart : NextPart;
type ExtractParams<Route> = Route extends Params
  ? Route extends `${infer Segment}/${infer Rest}`
    ? ExtractParam<Segment, ExtractParams<Rest>>
    : ExtractParam<Route, Record<string, string>>
  : Record<string, string>;
type RoutesWithParams = {
  [K in keyof RoutesEnum]: RoutesEnum[K] extends Params ? K : never;
}[keyof RoutesEnum];
export type RouteParams<T extends RoutesWithParams> = ExtractParams<RoutesEnum[T]>;

export default AppRoutes;
